import { ModalAction as Modal, PrimaryButton, SecondaryButton } from '@get-e/react-components';
import { CalendarTodayOutlined } from '@mui/icons-material/';
import { Box, Checkbox, Chip, Divider, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateRange, LocalizationProvider, PickersShortcutsProps, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { Dayjs } from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../constants/colors';
import { DATE_FORMATS } from '../../../constants/dateFormats';
import { datePickerPlaceholder } from '../../../constants/datePickerPlaceholder';
import { SUPPLIER_RIDES_FILTER } from '../../../constants/windowStorageKeys';
import {
    getCustomerRidesFilter,
    getSupplierRidesFilter,
    RideStatusFilter,
    RideStatusSupplierFilter,
} from '../../../helpers/rideStatusUtil';
import theme from '../../../styles/theme';
import { shortcutsItems } from '../supplier/helpers/dateRangeShortcuts';

const useStyles = makeStyles({
    clearFiltersButton: {
        marginBottom: '16px',
        marginTop: '32px',
    },
});

interface RidesMobileFiltersModalProps {
    onClose: () => void;
    isOpen: boolean;
    statusFilterMap: Map<string, string>;
    numberedValues: Map<string, string> | null;
    onSubmit: (values: { dateRange: DateRange<Dayjs>; status: string[] }) => void;
    page: 'CUSTOMER' | 'SUPPLIER';
}

const RidesMobileFiltersModal = ({
    onClose,
    isOpen,
    statusFilterMap,
    onSubmit,
    numberedValues,
    page,
}: RidesMobileFiltersModalProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const previousFilterState = page === 'SUPPLIER' ? getSupplierRidesFilter() : getCustomerRidesFilter();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const initialFiltersState = useMemo(
        () => (page === 'SUPPLIER' ? getSupplierRidesFilter() : getCustomerRidesFilter()),
        [page]
    );

    const [selectedFilters, setSelectedFilters] = useState<{
        dateRange: DateRange<Dayjs>;
        status: string[];
    }>({
        dateRange: initialFiltersState.dateRange,
        status: initialFiltersState?.status ?? [],
    });

    const handleApplyClick = () => {
        onSubmit(selectedFilters);
        onClose();
    };

    const handleClearFiltersClick = () => {
        setSelectedFilters({
            dateRange: [null, null],
            status: [],
        });
        onSubmit({
            dateRange: [null, null],
            status: [],
        });
        onClose();
    };

    const handleCheckboxChange = (itemKey: string, checked: boolean) => {
        if (checked) {
            setSelectedFilters(prevFilters => ({
                ...prevFilters,
                status: [...prevFilters.status, itemKey],
            }));
        } else {
            setSelectedFilters(prevFilters => ({
                ...prevFilters,
                status: prevFilters.status.filter(filter => filter !== itemKey),
            }));
        }
    };

    return (
        <Modal
            isFullScreen={isSmallScreen}
            isOpen={isOpen}
            onClose={onClose}
            title={t('pages.rides.filters')}
            onSubmit={() => {}}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateRangePicker
                    label={t('dateRangeLocal')}
                    sx={{ width: '100%', minWidth: '300px' }}
                    value={selectedFilters.dateRange}
                    onChange={newValue => {
                        setSelectedFilters(prevFilters => ({
                            ...prevFilters,
                            dateRange: newValue,
                        }));

                        const filters = JSON.stringify({
                            ...previousFilterState,
                            dateRange: newValue,
                        });

                        localStorage.setItem(SUPPLIER_RIDES_FILTER, filters);
                    }}
                    localeText={{
                        start: t('pages.rides.startingDate'),
                        end: t('pages.rides.endingDate'),
                        ...datePickerPlaceholder,
                    }}
                    slotProps={{
                        toolbar: {
                            hidden: true,
                        },
                        shortcuts: {
                            items: shortcutsItems,
                        },
                        textField: {
                            variant: 'filled',
                            placeholder: 'dd mmm y',
                            inputProps: { readOnly: true },
                            InputProps: {
                                endAdornment: <CalendarTodayOutlined style={{ color: COLORS.SLATE_GREY }} />,
                            },
                        },
                        mobilePaper: {
                            sx: {
                                maxWidth: '100vw',
                            },
                        },
                    }}
                    format={DATE_FORMATS['DD MMM YYYY']}
                    slots={{ field: SingleInputDateRangeField, shortcuts: CustomRangeShortcuts }}
                />
                <Box marginTop="10px">
                    {Array.from(statusFilterMap).map(([key, value]) => {
                        return (
                            <Box key={key}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography>
                                        {value}{' '}
                                        {key === 'TO_CONFIRM' &&
                                            Boolean(numberedValues) &&
                                            `(${numberedValues?.get('TO_CONFIRM')})`}
                                    </Typography>
                                    <Checkbox
                                        checked={selectedFilters.status.includes(
                                            page === 'CUSTOMER' ? RideStatusFilter[key] : RideStatusSupplierFilter[key]
                                        )}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleCheckboxChange(
                                                page === 'CUSTOMER' ? RideStatusFilter[key] : RideStatusSupplierFilter[key],
                                                event.target.checked
                                            )
                                        }
                                    />
                                </Box>
                            </Box>
                        );
                    })}
                </Box>

                <SecondaryButton className={classes.clearFiltersButton} onClick={handleClearFiltersClick}>
                    {t('pages.rides.clearFilters')}
                </SecondaryButton>
                <PrimaryButton onClick={handleApplyClick}>{t('pages.rides.apply')}</PrimaryButton>
            </LocalizationProvider>
        </Modal>
    );
};

export default RidesMobileFiltersModal;

function CustomRangeShortcuts(props: PickersShortcutsProps<DateRange<Dayjs>>) {
    const { items, onChange, isValid, changeImportance = 'accept' } = props;

    if (items === null || items?.length === 0) {
        return null;
    }

    const resolvedItems = items?.map(item => {
        const newValue = item.getValue({ isValid });

        return {
            label: item.label,
            onClick: () => {
                onChange(newValue, changeImportance, item);
            },
            disabled: !isValid(newValue),
        };
    });

    return (
        <Box
            sx={{
                gridRow: 1,
                gridColumn: 2,
                overflow: 'scroll',
                marginBottom: '20px',
                padding: '5px 10px',
            }}
        >
            <List
                dense
                sx={th => ({
                    display: 'flex',
                    px: th.spacing(4),
                    '& .MuiListItem-root': {
                        pt: 0,
                        pl: 0,
                        pr: th.spacing(1),
                    },
                })}
            >
                {resolvedItems?.map(item => {
                    return (
                        <ListItem key={item.label}>
                            <Chip {...item} />
                        </ListItem>
                    );
                })}
            </List>
            <Divider />
        </Box>
    );
}
