/* eslint-disable no-nested-ternary */
import { Spinner } from '@get-e/react-components';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { logAmplitudeEvent } from '../../../../amplitude/amplitude';
import { RIDE_INFORMATION_VEHICLE_CARD } from '../../../../constants/amplitude/customerKeys';
import { COLORS } from '../../../../constants/colors';
import SelectedVehicleCard from '../../customer/components/SelectedVehicleCard';
import { RideContext } from '../../customer/context/RideContext';
import NoVehicleAvailable from '../NoVehicleAvailable';

export interface Vehicle {
    id: string;
    vehicleClass: string;
    vehicleType: string;
    vehicleImage: string;
    travellers: string;
    luggages: string;
    price: number;
    priceCurrency: string;
    carId?: string;
}

interface VehiclesProp {
    selectedVehicle: Vehicle | null;
    setSelectedVehicle: Dispatch<SetStateAction<Vehicle | null>>;
    vehicleList: Vehicle[];
    isError?: boolean;
    setIsError?: () => void;
    isLoading: boolean;
    maxPassengers?: number;
}

const VehicleOptions = ({
    selectedVehicle,
    setSelectedVehicle,
    vehicleList = [],
    isError = false,
    setIsError,
    isLoading,
    maxPassengers,
}: VehiclesProp) => {
    const { t } = useTranslation();
    const { amplitudeEventProperties } = useContext(RideContext);

    useEffect(() => {
        if (vehicleList.length > 0 && selectedVehicle) {
            const selectedVehicleExists = vehicleList.some(
                vehicle => getUniqueVehicleId(selectedVehicle) === getUniqueVehicleId(vehicle)
            );

            if (!selectedVehicleExists) {
                setSelectedVehicle(vehicleList[0]);
            }
        }
    }, [selectedVehicle, vehicleList, setSelectedVehicle]);

    const onCardClick = (vehicle: Vehicle | null) => {
        setSelectedVehicle(vehicle);
        setIsError?.();

        logAmplitudeEvent(RIDE_INFORMATION_VEHICLE_CARD, amplitudeEventProperties);
    };

    const getUniqueVehicleId = (vehicle: Vehicle | null): string =>
        `${vehicle?.vehicleClass}${vehicle?.vehicleType}${vehicle?.travellers}${vehicle?.luggages}`;

    if (isLoading) {
        return (
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '200px',
                    justifyContent: 'center',
                }}
            >
                <Spinner size={24} />
            </Box>
        );
    }

    return (
        <Grid item marginBottom={'2rem'}>
            <Typography
                style={{
                    fontWeight: 700,
                    color: COLORS.BLUE_DARK,
                    margin: '2rem 0 1rem 0',
                }}
            >
                {t('pages.rides.selectVehicle')}
            </Typography>
            {vehicleList.length > 0 ? (
                vehicleList.map((vehicle: Vehicle) => {
                    return (
                        <SelectedVehicleCard
                            key={vehicle.id}
                            isSelected={getUniqueVehicleId(selectedVehicle) === getUniqueVehicleId(vehicle)}
                            onCardClick={() => onCardClick(vehicle)}
                            vehicle={vehicle}
                            isPassengerOverMax={parseInt(vehicle?.travellers) < (maxPassengers || 0)}
                        />
                    );
                })
            ) : selectedVehicle ? (
                <SelectedVehicleCard
                    isSelected
                    onCardClick={() => onCardClick(selectedVehicle)}
                    vehicle={selectedVehicle}
                    isPassengerOverMax={parseInt(selectedVehicle?.travellers) < (maxPassengers || 0)}
                />
            ) : (
                <NoVehicleAvailable />
            )}
            {isError && !selectedVehicle && <FormHelperText error>{t('errors.selectVehicle')}</FormHelperText>}
        </Grid>
    );
};

export default VehicleOptions;
